import classNames from "classnames";
import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { usePermissions } from "common/core/current_user_role";
import { ComboboxDropdown, ComboboxDropdownMenuOption } from "common/core/combobox_dropdown";
import { format } from "common/core/format/date";
import SROnly from "common/core/screen_reader";
import { SearchField } from "common/core/search_field";
import Table from "common/core/table";
import BinaryToggle from "common/form/inputs/binary_toggle";
import { ProofReasonCategory } from "graphql_globals";
import { useQuery } from "util/graphql";

import Styles from "./proof_reason_codes.module.scss";
import ProofReasonCodesQuery, {
  type ProofReasonCodes_viewer_proofReasonCodes as ProofReasonCode,
} from "./proof_reason_codes_query.graphql";

const MESSAGES = defineMessages({
  searchLabel: {
    id: "321bbc37-673d-4125-becc-9e37c2fca9bc",
    defaultMessage: "Search for reason codes",
  },
});

const formatDate = (value: string) => format({ value, formatStyle: "MM/dd/yyyy H:mmaaa" });

function filterCodes(codes: ProofReasonCode[], searchTerm: string): ProofReasonCode[] {
  searchTerm = searchTerm.toLocaleLowerCase();
  return codes.filter(
    (code) =>
      code.code.toLocaleLowerCase().includes(searchTerm) ||
      code.description.toLocaleLowerCase().includes(searchTerm) ||
      code.socureReasonCodes.some((socureCode) =>
        socureCode.code.toLocaleLowerCase().includes(searchTerm),
      ) ||
      code.categories.some((category) => category.toLocaleLowerCase().includes(searchTerm)),
  );
}

const EnabledColumn = {
  Header: (
    <span>
      <FormattedMessage id="38dc1ca3-02ab-40f0-9f19-35769c8b317c" defaultMessage="Enabled" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return (
      <BinaryToggle
        label={
          <SROnly>
            <FormattedMessage id="3b52535c-c4f7-4917-a2f9-44a0e60586ab" defaultMessage="Enabled" />
          </SROnly>
        }
        value={reasonCode.enabled}
        onChange={() => {}}
        disabled
      />
    );
  },
  collapse: false,
};

const ProofCodeColumn = {
  Header: (
    <span>
      <FormattedMessage id="a3fa266c-fcfa-4686-9025-030a7c4f42f6" defaultMessage="Proof Code" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{reasonCode.code}</span>;
  },
  collapse: false,
};

const CategoriesColumn = {
  Header: (
    <span>
      <FormattedMessage id="6a2e36b8-ae03-4b69-aacb-c4259ff4430b" defaultMessage="Categories" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return (
      <ComboboxDropdown
        className={classNames(Styles.categoriesWidth)}
        label="No categories selected"
        selectedLabel={reasonCode.categories.join(", ")}
        hasSelection={false} // don't want to show the clear button
        allowMultipleSelection
        noBorder
        noBackground
      >
        {() => (
          <>
            <ComboboxDropdownMenuOption
              selected={reasonCode.categories.includes(ProofReasonCategory.AGE)}
              onChange={() => {}}
              optionLabel={ProofReasonCategory.AGE}
              disabled
            />
            <ComboboxDropdownMenuOption
              selected={reasonCode.categories.includes(ProofReasonCategory.EMAIL)}
              onChange={() => {}}
              optionLabel={ProofReasonCategory.EMAIL}
              disabled
            />
            <ComboboxDropdownMenuOption
              selected={reasonCode.categories.includes(ProofReasonCategory.IDENTITY)}
              onChange={() => {}}
              optionLabel={ProofReasonCategory.IDENTITY}
              disabled
            />
          </>
        )}
      </ComboboxDropdown>
    );
  },
  collapse: false,
};

const SocureCodesColumn = {
  Header: (
    <span>
      <FormattedMessage id="50a1301e-d829-49a8-829d-42cac9581592" defaultMessage="Socure Codes" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{reasonCode.socureReasonCodes.map((sc) => sc.code).join(", ")}</span>;
  },
  collapse: false,
};

const DescriptionColumn = {
  Header: (
    <span>
      <FormattedMessage id="88313022-bc19-4ae0-9800-2984045a3cd5" defaultMessage="Description" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{reasonCode.description}</span>;
  },
  collapse: false,
};

const CreatedAtColumn = {
  Header: (
    <span>
      <FormattedMessage id="9cd28c29-614f-4211-84bc-7149f1d8407a" defaultMessage="Created At" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{formatDate(reasonCode.createdAt)}</span>;
  },
  collapse: false,
};

const UpdatedAtColumn = {
  Header: (
    <span>
      <FormattedMessage id="d4394fad-65b5-4930-9ad9-94b19c352620" defaultMessage="Updated At" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{formatDate(reasonCode.updatedAt)}</span>;
  },
  collapse: false,
};

export function ProofReasonCodes() {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");

  const { data, loading } = useQuery(ProofReasonCodesQuery);
  const { hasPermissionFor } = usePermissions();

  const handleSearchChange = useCallback((input: { value: string }) => {
    setSearchTerm(input.value);
  }, []);

  const canView = hasPermissionFor("viewProofReasonCodes");
  // const canEdit = hasPermissionFor("editProofReasonCodes");
  const allCodes = data?.viewer.proofReasonCodes ?? [];
  const filteredCodes = filterCodes(allCodes, searchTerm);

  if (!canView) {
    return (
      <div>
        <FormattedMessage
          id="e9b86eac-2be4-473c-9ea6-0a9b18df504f"
          defaultMessage="You do not have permission to view this page."
        />
      </div>
    );
  }

  return (
    <div>
      <SearchField
        className={Styles.searchField}
        value={searchTerm}
        onChange={handleSearchChange}
        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
      />
      <Table
        columns={[
          EnabledColumn,
          ProofCodeColumn,
          CategoriesColumn,
          DescriptionColumn,
          SocureCodesColumn,
          CreatedAtColumn,
          UpdatedAtColumn,
        ]}
        data={filteredCodes}
        totalItems={allCodes.length}
        loading={loading}
        caption="Proof Reason Codes"
      ></Table>
    </div>
  );
}
